import React from "react"
import { Helmet } from "react-helmet"
import TopBar from "../components/topBar";

import '../styles/App.scss';
import '../styles/Lead.scss';
import '../styles/Other.scss';

import rightBg from '../images/Icons_Abilities3c.png';
import BotBar from "../components/BotBar";

export default function deleteaccount() {
  const seo = {
    title: "Delete Account | Legends of Idleon",
    description: "Information on deleting your Idleon Account.",
    image: "https://www.LegendsOfIdleon.com/share.png",
    url: "https://www.LegendsOfIdleon.com/deleteAccount",
  }


  return (
    <div className="AppContainer" style={{ backgroundImage: `url(${rightBg})` }}>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta property="og:url" content={seo.url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:alt" content="Play Legends of Idleon" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />


        <link rel="icon" type="image/png" href="/favicon64.png" />
        <link rel="apple-touch-icon" href="/logo192_a.png" />
        <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@600&family=Oxygen&display=swap" rel="stylesheet"></link>

        {/*TODO <script type="application/ld+json">
          {`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "https://www.LegendsOfIdleon.com",
                "logo": "https://www.LegendsOfIdleon.com/logo192_a.png",
                "name": "Legends of Idleon",
              }
            `}
        </script> */}
      </Helmet>
      <div className="LeftGradient" />
      <div className="RightGradient" />
      <div className="App">
        <div className="Content2" style={{backgroundColor: "#393939"}}>
          <div className="Header" style={{position: "absolute"}}>
            <TopBar/>
          </div>

          <div className="RealContent">
            <header>
<i>The following steps provided below are here for any user who may wish to delete their IdleOn account and information.</i>
<br/>
<h1 style={{fontSize: "40px",
    margin: "16px 0 8px 0",
    textAlign: "center",
    fontFamily: "'Oxygen', sans-serif"}}>Warning: This is a permanent decision. </h1>
<h2 style={{fontSize: "32px",
    margin: "8px 0 40px 0",
    textAlign: "center",
    fontFamily: "'Oxygen', sans-serif"}}>You will lose everything on your account, including In App Purchases. This cannot be undone or reversed.</h2>
<ol>
    <li>Send an email to our contact support@lavaflame2.com. This email must be sent from the email attached to the IdleOn account you wish to delete.
    <br/><br/>
    </li>
    <li>Please include the name of your first IdleOn character on this account you are requesting be deleted within the body of this email, with subject line "IdleOn Account Deletion Request".
    <br/><br/>
    </li>
    <li>You can expect your account and relevant information to be deleted within 30 days under normal circumstances.
    </li>
</ol>
</header>
          </div>

          <BotBar/>
        </div>
      </div>
    </div>
  );
}
